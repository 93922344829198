.c-book {
    border-top: 1px solid rgba($BDC, 1);
    border-bottom: 1px solid rgba($BDC, 1);
    &__Innr {
        padding: 32px 0;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include mq($from: mobile, $until: desktop) {
            padding: 16px 0;
            width: 100%;
            display: block;
        }
        &-photo {
            margin-left: 48px;
            order: 2;
            @include mq($from: mobile, $until: desktop) {
                order: 1;
                margin-left: 0;
                margin-bottom: 32px;
            }
            img {
                border: 1px solid rgba($BDC, 1);
                width: width("9784910029993@2x.jpg", 4);
                height: height("9784910029993@2x.jpg", 4);
                @include mq($from: mobile, $until: desktop) {
                    width: 100%;
                    height: auto;
                }
            }
        }
        &-data {
            order: 1;
            @include mq($from: mobile, $until: desktop) {
                order: 2;
                width: 100%;
            }
            .button {
                margin-top: 16px;
            }
        }
    }
}