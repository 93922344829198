/*.l-header {
    border-bottom: 1px solid #ccc;
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;
    background: #FFF;
    width: 100%;
    min-width: 1240px;
    height: 44px;

    &__logo {
        width: 96px;
        height: 26px; // margin: 0 auto;
        padding: 9px 0 9px 24px;

        a {
            display: block;
            background: url("/img/logo-retina.png") no-repeat 0 0;
            background-size: 95px 27px;
            width: 95px;
            height: 27px;
        }

        span {
            display: none;
        }
    }

    &__menu {
        position: absolute;
        top: 0;
        right: 0;
        height: 44px;
        width: 68px;
        display: block;
        background: #FFF url("/img/menu.png") no-repeat center center;
        background-size: 20px 16px;

        &:hover {
            background-color: #ededed;
        }
    }
}*/