@charset "utf-8";

@mixin cf {
  zoom: 1;
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.cf {
  zoom: 1;
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin font-size ($sizeValue: 16) {
  $sizeRem: ($sizeValue / 10) - ((($sizeValue /10)*37.5)/100);
  font-size: $sizeValue + px;
  font-size: $sizeRem + rem;
}
@mixin fontsize ($sizeValue: 16) {
  $sizeRem: ($sizeValue / 10) - ((($sizeValue /10)*37.5)/100);
  font-size: $sizeValue + px;
  font-size: $sizeRem + rem;
}
