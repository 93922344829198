$badgeSize: 160px;
$badgeSize_sp: 120px;
.l-company {
    background-color: rgba($WHT, 1);
    &__Innr {
        padding: 64px 48px;
        position: relative;
        /* mobile 320px to 960px */
        @include mq($from: mobile, $until: desktop) {
            padding: 20px 24px 32px;
            width: 100%;
        }
        &-link {
            align-items: center;
            background-color: rgba($WHT, 1);
            border-radius: 50%;
            box-shadow: 0 0 8px rgba($TL, 0.16);
            cursor: pointer;
            display: flex;
            justify-content: center;
            position: absolute;
            right: 64px;
            top: 64px;
            height: $badgeSize;
            width: $badgeSize;
            /*
                mobile
                (320px to 480px)
            */
            @include mq($from: mobile, $until: tablet) {
                right: 32px;
                top: 64px;
                height: $badgeSize_sp;
                width: $badgeSize_sp;
            }
            /*
                tablet
                (740px to 960px)
            */
            @include mq($from: tablet, $until: desktop) {
                right: 48px;
                top: 48px;
                height: $badgeSize;
                width: $badgeSize;
            }
            &:hover {
                opacity: 0.8;
                text-decoration: none;
                figure {
                    figcaption {
                        color: rgba($TL, 1);
                    }
                }
            }
            figure {
                margin: 0;
                padding: 0;
                text-align: center;
                figcaption {
                    color: rgba($TL, 1);
                    @extend .f-jp_b;
                    @include font-size(16);
                    @include mq($from: mobile, $until: desktop) {
                        @include font-size(12);
                    }
                }
                img {
                    height: 24px;
                    width: 98px;
                    margin: 8px 0;
                    @include mq($from: mobile, $until: desktop) {
                        margin: 8px 0;
                        height: 18px;
                        width: auto;
                    }
                }
            }
        }
        &-body {
            h2 {
                color: rgba($BLU900, 1);
                margin-bottom: 0;
                padding-bottom: 16px;
                @extend .f-en_b;
                @extend .f-size_H6;
            }
            dl {
                align-items: center;
                color: rgba($TL, 1);
                display: flex;
                margin: 0;
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0;
                }
                @include mq($from: mobile, $until: desktop) {
                    display: block;
                    margin-bottom: 8px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                dt {
                    margin: 0;
                    min-width: 96px;
                    @extend .f-size_S;
                    @extend .f-jp_m;
                    @include mq($from: mobile, $until: desktop) {
                        margin-bottom: 4px;
                        min-width: auto;
                        @include f-jp_b;
                        @include f-size_S;
                    }
                }
                dd {
                    margin: 0;
                    a {
                        color: rgba($TL, 1);
                        @extend .f-jp_b;
                        text-decoration: underline;
                        &:hover {
                            color: rgba($HOV, 1);
                            text-decoration: none;
                        }
                    }
                    @extend .f-jp_m;
                    @extend .f-size_M;
                    @include mq($from: mobile, $until: desktop) {
                        margin-bottom: 4px;
                        min-width: auto;
                        @include f-size_S;
                    }
                }
            }
        }
    }
}