@charset "utf-8";

/*
    Brand Color
*/

$BC_DARKEST: #0066a8;
$BC_DARKER: #2ca6e0;
$BC: #71bbe8;
$BC_LIGHTER: #add7f2;
$BC_LIGHTEST: #d2ecfa;

/*
    MONO
*/

$WHT: #ffffff;
$BLK: #000000;

/*
    Gray
*/

$GRY100: #f4f4f4;
$GRY150: #f0f0f0;
$GRY200: #e6e6e6;
$GRY250: #d4d4d4;
$GRY300: #c9c9c9;
$GRY350: #bcbcbc;
$GRY400: #9a9a9a;
$GRY450: #8a8b8c;
$GRY500: #7b7c7d;
$GRY550: #67686a;
$GRY600: #434343;
$GRY700: #292929;
$GRY800: #181b1c;
$GRY850: #031021;
$GRY900: #0f0f0f;

/*
    Gray
*/

$GRY_LIGHTEST: $GRY100;
$GRY_LIGHTER: $GRY200;
$GRY_LIGHT: $GRY300;
$GRY: $GRY400;
$GRY_DARKER: $GRY500;
$GRY_DARKEST: $GRY700;

/*
    B/W Opacity
*/

$BOP0: rgba($BLK, 0.0);
$BOP1: rgba($BLK, 0.1);
$BOP2: rgba($BLK, 0.2);
$BOP3: rgba($BLK, 0.3);
$BOP4: rgba($BLK, 0.4);
$BOP5: rgba($BLK, 0.5);
$BOP6: rgba($BLK, 0.6);
$BOP7: rgba($BLK, 0.7);
$BOP8: rgba($BLK, 0.8);
$BOP9: rgba($BLK, 0.9);
$WOP0: rgba($WHT, 0.0);
$WOP1: rgba($WHT, 0.1);
$WOP2: rgba($WHT, 0.2);
$WOP3: rgba($WHT, 0.3);
$WOP4: rgba($WHT, 0.4);
$WOP5: rgba($WHT, 0.5);
$WOP6: rgba($WHT, 0.6);
$WOP7: rgba($WHT, 0.7);
$WOP8: rgba($WHT, 0.8);
$WOP9: rgba($WHT, 0.9);

/*
    Gray
*/

$BGE100: #f7f7f5;
$BGE200: #f8f8f3;
$BGE300: #f6efeb;
$BGE400: #f6fbfd;
$BGE500: #fef8f1;
$BGE600: #eceee8;
$BGE700: #f5fafc;

/*
    BLU
*/

$BLU100: $BGE700;
$BLU200: #a1b8e5;
$BLU: $BC;
$BLU600: #204068;
$BLU900: #003F71;

/*
    RED
*/

$RED100: $BGE500;
$RED200: #febfc4;
$RED400: #f80010;
$RED: $RED400;
$RED600: #cc000d;
$RED900: #4d0005;

/*
    PNK
*/

$PNK100: $BGE300;
$PNK200: #fdebe3;
$PNK400: #fb8287;
$PNK: $PNK400;
$PNK600: #fb4385;

/*
    ORG
*/

$ORG100: $BGE100;
$ORG200: #f3e0ca;
$ORG400: #fc9a40;
$ORG: $ORG400;
$ORG600: #bf6000;

/*
    YLW
*/

$YLW100: $BGE200;
$YLW200: #fdef6b;
$YLW400: #fcd340;
$YLW: $YLW400;
$YLW500: #ccab34;

/*
    LGR
*/

$LGR400: #3ad7be;
$LGR: $LGR400;

/*
    CYN
*/

$CYN100: $BGE700;
$CYN200: #bbeefd;
$CYN400: #40cffc;
$CYN: $CYN400;
$CYN600: #1fa2cc;

/*
    NVY
*/

$NVY100: $BGE700;
$NVY200: #577591;
$NVY400: #004c92;
$NVY: $NVY400;
$NVY600: #06296f;
$NVY900: #002d3d;

/*
    Green
*/

$GRN100: $BGE100;
$GRN200: #c5f4c6;
$GRN400: #00cc87;
$GRN: $GRN400;
$GRN600: #068057;
$GRN900: #003423;

/*
    Green
*/

$PDF: #e30007;
$YOUTUBE: #fc0007;
$NOTE: #30c2a6;
$TWITTER: #128cf3;
$FACEBOOK: #0a5af4;
$VIMEO: #15232e;
$AMAZON: #fd870a;

/*
    Brand Color
*/

$MAIN: $BLU900;
$SUB: $BC_DARKEST;

/*
    Parts Color
*/

$PRI: $MAIN;
$SEC: $SUB;
$TER: $GRY;

/*
    Status Color
*/

$ACCENT: $PNK;
$TLINK: $BLU;
$HOV: $ACCENT;
$FOCUS: $BLU;
$ERROR: $PNK;
$REQUIRE: $ACCENT;

/*border*/

//メイン・ボーダーカラー
$BDC: $GRY200;
//サブ・ボーダーカラー
$BDC_L: $GRY100;

/*
    Background
*/

//メイン・背景カラー
$BGC: $GRY200;
$BGC_L: $BGE200;
$BGC_E: $BGE500;
//サブ・背景カラー
$BGC_D: $GRY350;

/*
    Text Color
*/

//アイコン・テキストカラー
$ICN: $GRY300;
//ノーマル・テキストカラー
$TXT: $GRY850;
//サブ・テキストカラー
$TXT_L: $GRY600;
//サブ・テキストカラー
$TL: $BLU900;