.c-title {
    padding-bottom: 8px;
    border-bottom: 1px solid rgba($BDC, 1);
    margin-bottom: 8px;
    h3 {
        @extend .f-size_XL;
        line-height: 1.2;
        letter-spacing: 3px;
    }
    h5 {
        @extend .f-size_M;
        line-height: 1.2;
    }
    h4 {
        @extend .f-size_M;
        line-height: 1.2;
        span {
            @extend .f-size_S;
            margin-left: 4px;
            @extend .f-jp_m;
            color: rgba($GRY600, 1);
        }
    }
}