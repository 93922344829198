/*
本文
*/

.c-textBody {
    color: rgba($TXT, 1);
    padding-bottom: 8px;
    border-bottom: 1px solid rgba($BDC, 1);
    margin-bottom: 8px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    @extend .f-jp_m;
    @extend .f-size_M;
    /* @include mq($from: mobile, $until: tablet) {
        line-height: 1.714285714;

        @include font-size(14);
    } */
    &.--sm {
        @extend .f-jp_m;
        @extend .f-size_S;
    }
    &.--xs {
        @extend .f-jp_m;
        @extend .f-size_XS;
    }
}

.c-textBody {
    p.paragraph {
        color: rgba($TXT, 1);
        margin-bottom: 8px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        +.btnIc {
            margin-bottom: 8px;
        }
        +.c-h3 {
            margin-bottom: 8px;
        }
        +.c-dl {
            margin-bottom: 8px;
        }
        +.c-ol {
            margin-bottom: 8px;
        }
        +.c-ol_num {
            margin-bottom: 8px;
        }
        +.c-ul_disc {
            margin-bottom: 8px;
        }
        +.c-table {
            margin-bottom: 8px;
        }
    }
}