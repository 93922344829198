.l-bnr {
    &__Innr {
        position: relative;
        @include mq($from: mobile, $until: tablet) {
            width: 100%;
        }
        @include mq($from: tablet, $until: desktop) {
            width: 100%;
        }
        &-item {
            width: 100%;
            height: 400px;
            min-width: 1280px;
            @include mq($from: mobile, $until: tablet) {
                height: 120px;
                min-width: auto;
                padding: 0;
                width: 100%;
            }
            @include mq($from: tablet, $until: desktop) {
                height: 240px;
                padding: 0;
                width: 100%;
                min-width: auto;
            }
            .wrap {
                position: relative;
                z-index: 1;
                text-align: center;
            }
            .background {
                z-index: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                img {
                    object-fit: cover;
                    width: 100%;
                    height: auto;
                    min-height: 400px;
                    @include mq($from: tablet, $until: desktop) {
                        min-height: 240px;
                    }
                    @include mq($from: mobile, $until: tablet) {
                        min-height: 120px;
                    }
                }
            }
            &.is-note {
                background-color: #4bc7b4;
                text-align: center;
                height: 160px;
                padding: 0;
                align-items: center;
                display: flex;
                justify-content: center;
            }
            &.is-miroco {
                .wrap {
                    h2 {
                        img {
                            height: height("bnr-miroco_title@2x.png", 2.4);
                            width: auto;
                            @include mq($from: mobile, $until: tablet) {
                                height: height("bnr-miroco_title@2x.png", 8);
                                width: auto;
                            }
                            @include mq($from: tablet, $until: desktop) {
                                height: height("bnr-miroco_title@2x.png", 4);
                                width: auto;
                            }
                        }
                    }
                    h3 {
                        img {
                            height: height("bnr-miroco_name@2x.png", 2.4);
                            width: auto;
                            @include mq($from: mobile, $until: tablet) {
                                height: height("bnr-miroco_name@2x.png", 6);
                                width: auto;
                            }
                            @include mq($from: tablet, $until: desktop) {
                                height: height("bnr-miroco_name@2x.png", 3);
                                width: auto;
                            }
                        }
                    }
                }
            }
            &.is-faded-map {
                .wrap {
                    h2 {
                        img {
                            height: height("bnr-faded-map_title@2x.png", 2);
                            width: auto;
                            @include mq($from: mobile, $until: tablet) {
                                height: height("bnr-faded-map_title@2x.png", 5);
                                width: auto;
                            }
                            @include mq($from: tablet, $until: desktop) {
                                height: height("bnr-faded-map_title@2x.png", 2.8);
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
        &-link {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 100%;
            @include mq($from: mobile, $until: desktop) {
                width: 100%;
            }
            &:after {
                color: rgba($WHT, 1);
                content: "\f178";
                font-family: "Font Awesome 5 Pro";
                font-weight: 400;
                margin-left: 8px;
                position: absolute;
                right: 24px;
                top: calc(50% - 16px);
                z-index: 4;
                @include font-size(32);
                @include mq($from: mobile, $until: desktop) {
                    @include font-size(24);
                    right: calc(50% - 12px);
                    top: auto;
                    bottom: 24px;
                }
            }
            &:hover {
                opacity: 0.7;
                text-decoration: none;
            }
        }
    }
}