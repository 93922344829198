.l-hero {
    background-color: rgba($WHT, 1);
    /*
        mobile
        (320px to 480px)
    */
    @include mq($from: mobile, $until: tablet) {
        padding-bottom: 32px;
    }
    @include mq($from: tablet, $until: desktop) {
        padding-bottom: 48px;
    }
    &__Innr {
        padding: 40px 48px 64px;
        position: relative;
        /*
            mobile
            (320px to 480px)
        */
        @include mq($from: mobile, $until: mobileLandscape) {
            align-items: center;
            display: flex;
            height: calc(100vh - 240px);
            max-height: 480px;
            justify-content: center;
            overflow: hidden;
            padding: 0 48px;
            width: 100%;
        }
        /*
            mobileLandScape
            (480px to 740px)
        */
        @include mq($from: mobileLandscape, $until: tablet) {
            align-items: center;
            display: flex;
            height: calc(100vh - 40px);
            justify-content: center;
            max-height: 480px;
            overflow: hidden;
            padding: 0 48px;
            width: 100%;
        }
        /*
            tablet
            (740px to 960px)
        */
        @include mq($from: tablet, $until: desktop) {
            align-items: center;
            display: flex;
            height: calc(100vh - 480px);
            justify-content: center;
            overflow: hidden;
            padding: 0 48px;
            width: 100%;
        }
        &-logo {
            margin-bottom: 0;
            @extend .f-jp_b;
            /*
                mobile to tablet
                (320px to 740px)
            */
            @include mq($from: mobile, $until: mobileLandscape) {
                left: calc(50% - 59px);
                order: 0;
                padding-top: 32px;
                position: absolute;
                top: 0;
            }
            /*
                mobileLandScape
                (480px to 740px)
            */
            @include mq($from: mobileLandscape, $until: tablet) {
                left: calc(50% - 59px);
                order: 0;
                padding-top: 16px;
                position: absolute;
                top: 0;
            }
            /*
                tablet
                (740px to 960px)
            */
            @include mq($from: tablet, $until: desktop) {
                left: calc(50% - 78px);
                order: 0;
                padding-top: 32px;
                position: absolute;
                top: 0;
            }
            img {
                height: 40px;
                width: auto;
                /*
                    mobile
                    (320px to 740px)
                */
                @include mq($from: mobile, $until: mobileLandscape) {
                    height: 24px;
                    width: auto;
                }
                /*
                    mobileLandScape
                    (480px to 740px)
                */
                @include mq($from: mobileLandscape, $until: tablet) {
                    height: 20px;
                    width: auto;
                }
                /*
                    tablet
                    (740px to 960px)
                */
                @include mq($from: tablet, $until: desktop) {
                    height: 32px;
                    width: auto;
                }
            }
        }
        &-body {
            /*
                mobile
                (320px to 480px)
            */
            @include mq($from: mobile, $until: mobileLandscape) {
                left: 22px;
                overflow: auto;
                position: absolute;
                top: 88px;
                width: calc(100% - 44px);
                height: calc(100% - 88px);
            }
            /*
                mobileLandScape
                (480px to 740px)
            */
            @include mq($from: mobileLandscape, $until: tablet) {
                left: 22px;
                overflow: auto;
                position: absolute;
                top: 64px;
                width: calc(100% - 48px);
                height: calc(100% - 64px);
            }
            /*
                tablet
                (740px to 960px)
            */
            @include mq($from: tablet, $until: desktop) {
                left: 22px;
                overflow: auto;
                position: absolute;
                top: 112px;
                width: calc(100% - 44px);
                height: calc(100% - 112px);
            }
            .paragraph {
                color: rgba($BLU600, 1);
                line-height: 1.725;
                font-feature-settings: normal;
                /* mobile 320px to 960px */
                @include mq($from: mobile, $until: desktop) {
                    height: 100%;
                    overflow-x: scroll;
                    padding-bottom: 0;
                    text-align: justify;
                    width: 100%;
                    writing-mode: vertical-rl;
                    @include f-size_H4;
                }
                &:last-child {
                    padding-bottom: 0;
                }
                &-body {
                    @extend .f-jp_m;
                    @extend .f-size_M;
                    /*
                        mobileLandScape
                        (480px to 740px)
                    */
                    p {
                        margin-bottom: 16px;
                        @include mq($from: mobile, $until: desktop) {
                            margin-left: 16px;
                            margin-bottom: 0;
                            text-indent: 1em;
                        }
                    }
                }
                &-title {
                    color: rgba($BLU900, 1);
                    letter-spacing: 0.125em;
                    margin-bottom: 0;
                    padding-bottom: 16px;
                    @extend .f-jp_b;
                    @extend .f-size_H4;
                    /*
                        mobileLandScape
                        (480px to 740px)
                    */
                    @include mq($from: tablet, $until: desktop) {
                        letter-spacing: 0;
                    }
                    /*
                        mobile
                        (320px to 480px)
                    */
                    @include mq($from: mobile, $until: tablet) {
                        letter-spacing: 0;
                        line-height: 1.6;
                        margin-right: 4px;
                        margin-left: 24px;
                        margin-top: 1em;
                    }
                    /*
                        tablet
                        (740px to 960px)
                    */
                    @include mq($from: tablet, $until: desktop) {
                        margin-left: 16px;
                    }
                }
                &-lead {
                    color: rgba($BLU900, 1);
                    margin-bottom: 24px;
                    @extend .f-jp_b;
                    @extend .f-size_H5;
                    /* mobile 740px to 960px */
                    @include mq($from: tablet, $until: desktop) {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    /* mobile 320px to 740px */
                    @include mq($from: mobile, $until: tablet) {
                        line-height: 1.6;
                        margin-top: 0;
                        margin-bottom: 0;
                        text-indent: 1em;
                        writing-mode: vertical-rl;
                    }
                    @include mq($from: tablet, $until: desktop) {
                        margin-right: 8px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}