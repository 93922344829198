.c-spec {
    .group {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 4px;
        @include mq($from: mobile, $until: desktop) {
            display: block;
            text-align-last: left;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .c-dlMulti {
        margin-right: 16px;
        &:last-child {
            margin-right: 0;
        }
        dt {
            line-height: 1;
        }
        dd {
            color: rgba($GRY700, 1);
        }
    }
}