dl.c-dlMulti {
    align-items: center;
    display: flex;
    padding: 0;
    margin: 0;
    @include mq($from: mobile, $until: desktop) {
        display: block;
        text-align: center;
    }
    dt {
        color: rgba($TXT_L, 1);
        @extend .f-size_M;
        @extend .f-jp_m;
        padding: 0;
        margin: 0 8px 0 0;
    }
    dd {
        color: rgba($TXT, 1);
        @extend .f-size_M;
        @extend .f-jp_b;
        padding: 0;
        margin: 0;
        span {
            @extend .f-size_XS;
            margin-left: 4px;
        }
        @include mq($from: mobile, $until: desktop) {
            margin-left: 0;
        }
    }
    &.is-S {
        dt {
            @extend .f-size_S;
            @extend .f-jp_m;
        }
        dd {
            @extend .f-size_S;
            @extend .f-jp_b;
        }
        &.has-unit {
            dd {
                &:after {
                    @extend .f-size_XS;
                }
            }
        }
    }
    &.is-XS {
        dt {
            @extend .f-size_XS;
            @extend .f-jp_m;
        }
        dd {
            @extend .f-size_XS;
            @extend .f-jp_b;
        }
        &.has-unit {
            dd {
                &:after {
                    @extend .f-size_XS;
                }
            }
        }
    }
    &.has-unit {
        dd {
            margin-right: 8px;
            &:last-child {
                margin-right: 0;
            }
            &:after {
                color: rgba($TXT_L, 1);
                margin-left: 4px;
                @extend .f-size_S;
            }
        }
        &.--LV {
            dd {
                &:after {
                    content: "Lv";
                }
            }
        }
        &.--CM {
            dd {
                &:after {
                    content: "cm";
                }
            }
        }
        &.--MM {
            dd {
                &:after {
                    content: "mm";
                }
            }
        }
        &.--MOUNT {
            dd {
                &:after {
                    content: "個";
                }
            }
        }
    }
}