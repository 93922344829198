@charset "utf-8";

/*
    RebootCSS
*/

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/reboot";

/*
    Sass-mq
*/

@import "../../node_modules/sass-mq/_mq.scss";

/*
    FontAwesome5
*/

// $fa-font-path:"../webfonts";
// @import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands.scss";
// @import "../../node_modules/@fortawesome/fontawesome-pro/scss/duotone.scss";
// @import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss";
// @import "../../node_modules/@fortawesome/fontawesome-pro/scss/light.scss";
// @import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular.scss";
// @import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid.scss";
// @import "../../node_modules/@fortawesome/fontawesome-pro/scss/v4-shims.scss";

/*
    FontAwesome5
*/

$fa-font-path: "../webfonts";
@import "Object/Utility/fontawesome/brands";
// @import "Object/Utility/fontawesome/duotone";
@import "Object/Utility/fontawesome/fontawesome";
// @import "Object/Utility/fontawesome/light";
// @import "Object/Utility/fontawesome/regular";
@import "Object/Utility/fontawesome/solid";

/*
    Base
*/

@import "Foundation/_color.scss";
@import "Foundation/_font.scss";
@import "Foundation/_helper.scss";
@import "Foundation/_mixin.scss";
@import "Foundation/_mq-setting.scss";
@import "Foundation/_mq.scss";
@import "Foundation/_sanitize.scss";
@import "Layout/_bnr.scss";
@import "Layout/_book.scss";
@import "Layout/_common.scss";
@import "Layout/_company.scss";
@import "Layout/_footer.scss";
@import "Layout/_header.scss";
@import "Layout/_hero.scss";
@import "Layout/_information.scss";
@import "object/component/_book.scss";
@import "object/component/_common.scss";
@import "object/component/_dlMulti.scss";
@import "object/component/_spec.scss";
@import "object/component/_textBody.scss";
@import "object/component/_title.scss";

@import "object/Utility/fontawesome/_animated.scss";
@import "object/Utility/fontawesome/_bordered-pulled.scss";
@import "object/Utility/fontawesome/_core.scss";
@import "object/Utility/fontawesome/_fixed-width.scss";
@import "object/Utility/fontawesome/_icons.scss";
@import "object/Utility/fontawesome/_larger.scss";
@import "object/Utility/fontawesome/_list.scss";
@import "object/Utility/fontawesome/_mixins.scss";
@import "object/Utility/fontawesome/_rotated-flipped.scss";
@import "object/Utility/fontawesome/_screen-reader.scss";
@import "object/Utility/fontawesome/_stacked.scss";
@import "object/Utility/fontawesome/_variables.scss";
@import "object/Utility/fontawesome/brands.scss";
@import "object/Utility/fontawesome/fontawesome.scss";
@import "object/Utility/fontawesome/solid.scss";