.l-information {
    background-color: rgba($BLU900, .035);
    &__Innr {
        padding: 64px 48px;
        position: relative;
        /* mobile 320px to 960px */
        @include mq($from: mobile, $until: desktop) {
            padding: 32px 24px 32px;
            width: 100%;
        }
        &-body {
            @include mq($from: mobile, $until: desktop) {
                width: 100%;
            }
            h2 {
                color: rgba($BLU900, 1);
                margin-bottom: 0;
                padding-bottom: 16px;
                @extend .f-en_b;
                @extend .f-size_H6;
                @include mq($from: mobile, $until: desktop) {}
            }
            h3 {
                color: rgba($BLU900, 1);
                margin-bottom: 0;
                margin-top: 32px;
                padding-bottom: 16px;
                @extend .f-en_b;
                @extend .f-size_H6;
            }
            .group {
                max-height: 160px;
                overflow-y: auto;
            }
            dl {
                align-items: center;
                color: rgba($BLU900, 1);
                display: flex;
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0;
                }
                @include mq($from: mobile, $until: desktop) {
                    display: block;
                    margin-bottom: 8px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                dt {
                    margin: 0;
                    min-width: 96px;
                    @extend .f-en_b;
                    @extend .f-size_S;
                    @include mq($from: mobile, $until: desktop) {
                        margin-bottom: 4px;
                        min-width: auto;
                    }
                }
                dd {
                    margin: 0;
                    @extend .f-size_M;
                    a {
                        color: rgba($BLU900, 1);
                        text-decoration: underline;
                        &:hover {
                            color: rgba($HOV, 1);
                            text-decoration: none;
                        }
                    }
                    @include mq($from: mobile, $until: desktop) {
                        margin-bottom: 4px;
                        min-width: auto;
                    }
                }
            }
        }
    }
}