@charset "UTF-8";

$mq-base-font-size: 16px !default;
$mq-responsive: true !default;
$mq-breakpoints: (
    mobile:  321px,
    tablet:  740px,
    desktop: 980px,
    wide:    1300px
) !default;
$mq-static-breakpoint: desktop !default;
$mq-show-breakpoints: () !default;
$mq-media-type: all !default;

@function mq-px2em($px, $base-font-size: $mq-base-font-size) {
    @if unitless($px) {
        @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels.";
        @return mq-px2em($px * 1px, $base-font-size);
    } @else if unit($px) == em {
        @return $px;
    }
    @return ($px / $base-font-size) * 1em;
}

@function mq-get-breakpoint-width($name, $breakpoints: $mq-breakpoints) {
    @if map-has-key($breakpoints, $name) {
        @return map-get($breakpoints, $name);
    } @else {
        @warn "Breakpoint #{$name} wasn't found in $breakpoints.";
    }
}

@mixin mq(
    $from: false,
    $until: false,
    $and: false,
    $media-type: $mq-media-type,
    $breakpoints: $mq-breakpoints,
    $responsive: $mq-responsive,
    $static-breakpoint: $mq-static-breakpoint
) {
    $min-width: 0;
    $max-width: 0;
    $media-query: '';

    @if $from {
        @if type-of($from) == number {
            $min-width: mq-px2em($from);
        } @else {
            $min-width: mq-px2em(mq-get-breakpoint-width($from, $breakpoints));
        }
    }

    @if $until {
        @if type-of($until) == number {
            $max-width: mq-px2em($until);
        } @else {
            $max-width: mq-px2em(mq-get-breakpoint-width($until, $breakpoints)) - .01em;
        }
    }

    @if $responsive == false {
        $static-breakpoint-width: mq-get-breakpoint-width($static-breakpoint, $breakpoints);
        $target-width: mq-px2em($static-breakpoint-width);

        @if (
            $and == false
            and $min-width <= $target-width
            and (
                $until == false or $max-width >= $target-width
            )
        ) {
            @content;
        }
    }

    @else {
        @if $min-width != 0 { $media-query: '#{$media-query} and (min-width: #{$min-width})'; }
        @if $max-width != 0 { $media-query: '#{$media-query} and (max-width: #{$max-width})'; }
        @if $and            { $media-query: '#{$media-query} and #{$and}'; }

        @if ($media-type == 'all' and $media-query != '') {
            $media-type: '';
            $media-query: str-slice(unquote($media-query), 6);
        }
        @media #{$media-type + $media-query} {
            @content;
        }
    }
}

@mixin mq-add-breakpoint($name, $width) {
    $new-breakpoint: ($name: $width);
    $mq-breakpoints: map-merge($mq-breakpoints, $new-breakpoint) !global;
}

@mixin mq-show-breakpoints($show-breakpoints: $mq-show-breakpoints, $breakpoints: $mq-breakpoints) {
    body:before {
        background-color: #FCF8E3;
        border-bottom: 1px solid #FBEED5;
        border-left: 1px solid #FBEED5;
        color: #C09853;
        font: small-caption;
        padding: 3px 6px;
        pointer-events: none;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 100;

        @each $show-breakpoint in $show-breakpoints {
            $width: mq-get-breakpoint-width($show-breakpoint, $breakpoints);
            @include mq($show-breakpoint, $breakpoints: $breakpoints) {
                content: "#{$show-breakpoint} ≥ #{$width} (#{mq-px2em($width)})";
            }
        }
    }
}

@if length($mq-show-breakpoints) > 0 {
    @include mq-show-breakpoints;
}
