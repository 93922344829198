.l-footer {
    background-color: rgba($WHT, 1);
    &__Innr {
        padding: 0 48px 64px;
        /* mobile 320px to 960px */
        @include mq($from: mobile, $until: desktop) {
            padding: 0 24px 32px;
            width: 100%;
        }
        &-copyright {
            margin-top: 40px;
            @extend .f-size_XS;
            color: rgba($BLU900, 1);
            @include mq($from: mobile, $until: desktop) {
                text-align: center;
            }
        }
        &-body {
            /* mobile 320px to 960px */
            @include mq($from: mobile, $until: desktop) {
                text-align: center;
            }
            img {
                height: 72px;
                width: auto;
                /* mobile 320px to 960px */
                @include mq($from: mobile, $until: desktop) {
                    height: 48px;
                    width: auto;
                }
            }
        }
    }
}