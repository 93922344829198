.l-book {
    background-color: rgba($WHT, .035);
    &__Innr {
        padding: 64px 48px;
        position: relative;
        /* mobile 320px to 960px */
        @include mq($from: mobile, $until: desktop) {
            padding: 32px 24px 32px;
            width: 100%;
        }
        &-body {
            width: 1152px;
            margin: 0 auto;
            @include mq($from: mobile, $until: desktop) {
                width: 100%;
            }
            h2 {
                color: rgba($BLU900, 1);
                margin-bottom: 0;
                padding-bottom: 16px;
                @extend .f-en_b;
                @extend .f-size_H6;
                @include mq($from: mobile, $until: desktop) {}
            }
        }
    }
}