.c-textLink {
    text-decoration: none;
    &.--black {
        color: rgba($TXT, 1);
    }
    &.--white {
        color: rgba($TL, 1);
    }
    &:hover {
        color: rgba($HOV, 1) !important;
        text-decoration: none;
    }
}