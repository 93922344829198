// Variables
// --------------------------

$fa-font-path:         "../webfonts" !default;
$fa-font-size-base:    16px !default;
$fa-font-display:      block !default;
$fa-css-prefix:        fa !default;
$fa-version:           "5.15.1" !default;
$fa-border-color:      #eee !default;
$fa-inverse:           #fff !default;
$fa-li-width:          2em !default;
$fa-fw-width:          (20em / 16);
$fa-primary-opacity:   1 !default;
$fa-secondary-opacity: .4 !default;

// Convenience function used to set content property
@function fa-content($fa-var) {
  @return unquote("\"#{ $fa-var }\"");
}

$fa-var-amazon: \f270;
$fa-var-asterisk: \f069;
$fa-var-award: \f559;
$fa-var-badge: \f335;
$fa-var-bell: \f0f3;
$fa-var-book: \f02d;
$fa-var-book-open: \f518;
$fa-var-bookmark: \f02e;
$fa-var-books: \f5db;
$fa-var-calendar: \f133;
$fa-var-calendar-alt: \f073;
$fa-var-caret-down: \f0d7;
$fa-var-caret-left: \f0d9;
$fa-var-caret-right: \f0da;
$fa-var-caret-up: \f0d8;
$fa-var-certificate: \f0a3;
$fa-var-check: \f00c;
$fa-var-check-circle: \f058;
$fa-var-chevron-down: \f078;
$fa-var-chevron-left: \f053;
$fa-var-chevron-right: \f054;
$fa-var-chevron-up: \f077;
$fa-var-circle: \f111;
$fa-var-cloud-download: \f0ed;
$fa-var-comment: \f075;
$fa-var-comment-alt: \f27a;
$fa-var-dot-circle: \f192;
$fa-var-egg: \f7fb;
$fa-var-envelope: \f0e0;
$fa-var-facebook: \f09a;
$fa-var-file-pdf: \f1c1;
$fa-var-hashtag: \f292;
$fa-var-head-side: \f6e9;
$fa-var-heart: \f004;
$fa-var-home-alt: \f80a;
$fa-var-location-circle: \f602;
$fa-var-long-arrow-alt-down: \f309;
$fa-var-long-arrow-alt-left: \f30a;
$fa-var-long-arrow-alt-right: \f30b;
$fa-var-long-arrow-alt-up: \f30c;
$fa-var-minus: \f068;
$fa-var-moon: \f186;
$fa-var-mouse-pointer: \f245;
$fa-var-newspaper: \f1ea;
$fa-var-paper-plane: \f1d8;
$fa-var-pennant: \f456;
$fa-var-play: \f04b;
$fa-var-plus: \f067;
$fa-var-question: \f128;
$fa-var-quote-left: \f10d;
$fa-var-quote-right: \f10e;
$fa-var-search: \f002;
$fa-var-search-minus: \f010;
$fa-var-search-plus: \f00e;
$fa-var-sign-in: \f090;
$fa-var-sign-out: \f08b;
$fa-var-slash: \f715;
$fa-var-sort: \f0dc;
$fa-var-sort-down: \f0dd;
$fa-var-sort-up: \f0de;
$fa-var-star: \f005;
$fa-var-stop: \f04d;
$fa-var-times: \f00d;
$fa-var-trash-alt: \f2ed;
$fa-var-twitter: \f099;
$fa-var-undo-alt: \f2ea;
$fa-var-user: \f007;
$fa-var-vimeo: \f40a;
$fa-var-window-restore: \f2d2;
$fa-var-youtube: \f167;
