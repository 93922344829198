@charset "utf-8";
%legacy-pie-clearfix {
    *zoom: 1;
    &:after {
        content: "\0020";
        display: block;
        height: 0;
        clear: both;
        overflow: hidden;
        visibility: hidden;
    }
}

%pie-clearfix {
    *zoom: 1;
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

%clearfix {
    overflow: hidden;
    *zoom: 1;
}

.cf {
    @extend %clearfix;
}
%cf {
    @extend %clearfix;
}

.is-hidden {
    display: none;
    visibility: hidden;
}

.mt0 {
    margin-top: 0px !important;
}

.mb0 {
    margin-bottom: 0px !important;
}

.mr0 {
    margin-right: 0px !important;
}

.ml0 {
    margin-left: 0px !important;
}

.mt4 {
    margin-top: 4px !important;
}

.mb4 {
    margin-bottom: 4px !important;
}

.mr4 {
    margin-right: 4px !important;
}

.ml4 {
    margin-left: 4px !important;
}

.mt6 {
    margin-top: 6px !important;
}

.mb6 {
    margin-bottom: 6px !important;
}

.mr6 {
    margin-right: 6px !important;
}

.ml6 {
    margin-left: 6px !important;
}

.mt8 {
    margin-top: 8px !important;
}

.mb8 {
    margin-bottom: 8px !important;
}

.mr8 {
    margin-right: 8px !important;
}

.ml8 {
    margin-left: 8px !important;
}

.mt10 {
    margin-top: 10px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.mr10 {
    margin-right: 10px !important;
}

.ml10 {
    margin-left: 10px !important;
}

.mt12 {
    margin-top: 12px !important;
}

.mb12 {
    margin-bottom: 12px !important;
}

.mr12 {
    margin-right: 12px !important;
}

.ml12 {
    margin-left: 12px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mr20 {
    margin-right: 20px !important;
}

.ml20 {
    margin-left: 20px !important;
}

.mt30 {
    margin-top: 30px !important;
}

.mb30 {
    margin-bottom: 30px !important;
}

.mr30 {
    margin-right: 30px !important;
}

.ml30 {
    margin-left: 30px !important;
}

.mt40 {
    margin-top: 40px !important;
}

.mb40 {
    margin-bottom: 40px !important;
}

.mr40 {
    margin-right: 40px !important;
}

.ml40 {
    margin-left: 40px !important;
}

.mt60 {
    margin-top: 60px !important;
}

.mb60 {
    margin-bottom: 60px !important;
}

.mr60 {
    margin-right: 60px !important;
}

.ml60 {
    margin-left: 60px !important;
}

.mt64 {
    margin-top: 64px !important;
}

.mb64 {
    margin-bottom: 64px !important;
}

.mt12 {
    margin-top: 12px !important;
}

.mb12 {
    margin-bottom: 12px !important;
}

.mr12 {
    margin-right: 12px !important;
}

.ml12 {
    margin-left: 12px !important;
}

.mt24 {
    margin-top: 24px !important;
}

.mb24 {
    margin-bottom: 24px !important;
}

.mr24 {
    margin-right: 24px !important;
}

.ml24 {
    margin-left: 24px !important;
}

.mt36 {
    margin-top: 36px !important;
}

.mb36 {
    margin-bottom: 36px !important;
}

.mr36 {
    margin-right: 36px !important;
}

.ml36 {
    margin-left: 36px !important;
}

.mt48 {
    margin-top: 48px !important;
}

.mb48 {
    margin-bottom: 48px !important;
}

.mr48 {
    margin-right: 48px !important;
}

.ml48 {
    margin-left: 48px !important;
}

.mt60 {
    margin-top: 60px !important;
}

.mb60 {
    margin-bottom: 60px !important;
}

.mr60 {
    margin-right: 60px !important;
}

.ml60 {
    margin-left: 60px !important;
}

.pt0 {
    padding-top: 0 !important;
}

.pb0 {
    padding-bottom: 0 !important;
}

.pr0 {
    padding-right: 0 !important;
}

.pl0 {
    padding-left: 0 !important;
}

.pt7 {
    padding-top: 7px !important;
}

.pb7 {
    padding-bottom: 7px !important;
}

.pr7 {
    padding-right: 7px !important;
}

.pl7 {
    padding-left: 7px !important;
}

.pt8 {
    padding-top: 8px !important;
}

.pb8 {
    padding-bottom: 8px !important;
}

.pr8 {
    padding-right: 8px !important;
}

.pl8 {
    padding-left: 8px !important;
}

.pt10 {
    padding-top: 10px !important;
}

.pb10 {
    padding-bottom: 10px !important;
}

.pr10 {
    padding-right: 10px !important;
}

.pl10 {
    padding-left: 10px !important;
}

.pt15 {
    padding-top: 15px !important;
}

.pb15 {
    padding-bottom: 15px !important;
}

.pr15 {
    padding-right: 15px !important;
}

.pl15 {
    padding-left: 15px !important;
}

.pt20 {
    padding-top: 20px !important;
}

.pb20 {
    padding-bottom: 20px !important;
}

.pr20 {
    padding-right: 20px !important;
}

.pl20 {
    padding-left: 20px !important;
}

.pt30 {
    padding-top: 30px !important;
}

.pb30 {
    padding-bottom: 30px !important;
}

.pr30 {
    padding-right: 30px !important;
}

.pl30 {
    padding-left: 30px !important;
}

.pt40 {
    padding-top: 40px !important;
}

.pb40 {
    padding-bottom: 40px !important;
}

.pr40 {
    padding-right: 40px !important;
}

.pl40 {
    padding-left: 40px !important;
}

.pt60 {
    padding-top: 60px !important;
}

.pb60 {
    padding-bottom: 60px !important;
}

.pr60 {
    padding-right: 60px !important;
}

.pl60 {
    padding-left: 60px !important;
}

.pt12 {
    padding-top: 12px !important;
}

.pb12 {
    padding-bottom: 12px !important;
}

.pr12 {
    padding-right: 12px !important;
}

.pl12 {
    padding-left: 12px !important;
}

.pt24 {
    padding-top: 24px !important;
}

.pb24 {
    padding-bottom: 24px !important;
}

.pr24 {
    padding-right: 24px !important;
}

.pl24 {
    padding-left: 24px !important;
}

.pt36 {
    padding-top: 36px !important;
}

.pb36 {
    padding-bottom: 36px !important;
}

.pr36 {
    padding-right: 36px !important;
}

.pl36 {
    padding-left: 36px !important;
}

.pt48 {
    padding-top: 48px !important;
}

.pb48 {
    padding-bottom: 48px !important;
}

.pr48 {
    padding-right: 48px !important;
}

.pl48 {
    padding-left: 48px !important;
}



@include mq($from: mobile, $until: tablet) {
    br.sp {
        display: block;
    }
    br.tb {
        display: none;
    }
    br.pc {
        display: none;
    }
}

@include mq($from: tablet, $until: desktop) {
    br.sp {
        display: none;
    }
    br.tb {
        display: block;
    }
    br.pc {
        display: none;
    }
}

@include mq($from: desktop) {
    br.sp {
        display: none;
    }
    br.tb {
        display: none;
    }
    br.pc {
        display: block;
    }
}