@charset "utf-8";
html {
    overflow: auto;
}

body {
    background-color: $WHT;
    -webkit-font-smoothing: antialiased;
    min-width: 1240px;
    overflow: hidden;
    @include fontsize(10);
    @include mq($from: mobile, $until: desktop) {
        min-width: auto;
        width: 100%;
    }
}