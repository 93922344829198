/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

.#{$fa-css-prefix}-amazon:before { content: fa-content($fa-var-amazon); }
.#{$fa-css-prefix}-asterisk:before { content: fa-content($fa-var-asterisk); }
.#{$fa-css-prefix}-award:before { content: fa-content($fa-var-award); }
.#{$fa-css-prefix}-badge:before { content: fa-content($fa-var-badge); }
.#{$fa-css-prefix}-bell:before { content: fa-content($fa-var-bell); }
.#{$fa-css-prefix}-book:before { content: fa-content($fa-var-book); }
.#{$fa-css-prefix}-book-open:before { content: fa-content($fa-var-book-open); }
.#{$fa-css-prefix}-bookmark:before { content: fa-content($fa-var-bookmark); }
.#{$fa-css-prefix}-books:before { content: fa-content($fa-var-books); }
.#{$fa-css-prefix}-calendar:before { content: fa-content($fa-var-calendar); }
.#{$fa-css-prefix}-calendar-alt:before { content: fa-content($fa-var-calendar-alt); }
.#{$fa-css-prefix}-caret-down:before { content: fa-content($fa-var-caret-down); }
.#{$fa-css-prefix}-caret-left:before { content: fa-content($fa-var-caret-left); }
.#{$fa-css-prefix}-caret-right:before { content: fa-content($fa-var-caret-right); }
.#{$fa-css-prefix}-caret-up:before { content: fa-content($fa-var-caret-up); }
.#{$fa-css-prefix}-certificate:before { content: fa-content($fa-var-certificate); }
.#{$fa-css-prefix}-check:before { content: fa-content($fa-var-check); }
.#{$fa-css-prefix}-check-circle:before { content: fa-content($fa-var-check-circle); }
.#{$fa-css-prefix}-chevron-down:before { content: fa-content($fa-var-chevron-down); }
.#{$fa-css-prefix}-chevron-left:before { content: fa-content($fa-var-chevron-left); }
.#{$fa-css-prefix}-chevron-right:before { content: fa-content($fa-var-chevron-right); }
.#{$fa-css-prefix}-chevron-up:before { content: fa-content($fa-var-chevron-up); }
.#{$fa-css-prefix}-circle:before { content: fa-content($fa-var-circle); }
.#{$fa-css-prefix}-cloud-download:before { content: fa-content($fa-var-cloud-download); }
.#{$fa-css-prefix}-comment:before { content: fa-content($fa-var-comment); }
.#{$fa-css-prefix}-comment-alt:before { content: fa-content($fa-var-comment-alt); }
.#{$fa-css-prefix}-dot-circle:before { content: fa-content($fa-var-dot-circle); }
.#{$fa-css-prefix}-egg:before { content: fa-content($fa-var-egg); }
.#{$fa-css-prefix}-envelope:before { content: fa-content($fa-var-envelope); }
.#{$fa-css-prefix}-facebook:before { content: fa-content($fa-var-facebook); }
.#{$fa-css-prefix}-file-pdf:before { content: fa-content($fa-var-file-pdf); }
.#{$fa-css-prefix}-hashtag:before { content: fa-content($fa-var-hashtag); }
.#{$fa-css-prefix}-head-side:before { content: fa-content($fa-var-head-side); }
.#{$fa-css-prefix}-heart:before { content: fa-content($fa-var-heart); }
.#{$fa-css-prefix}-home-alt:before { content: fa-content($fa-var-home-alt); }
.#{$fa-css-prefix}-location-circle:before { content: fa-content($fa-var-location-circle); }
.#{$fa-css-prefix}-long-arrow-alt-down:before { content: fa-content($fa-var-long-arrow-alt-down); }
.#{$fa-css-prefix}-long-arrow-alt-left:before { content: fa-content($fa-var-long-arrow-alt-left); }
.#{$fa-css-prefix}-long-arrow-alt-right:before { content: fa-content($fa-var-long-arrow-alt-right); }
.#{$fa-css-prefix}-long-arrow-alt-up:before { content: fa-content($fa-var-long-arrow-alt-up); }
.#{$fa-css-prefix}-minus:before { content: fa-content($fa-var-minus); }
.#{$fa-css-prefix}-moon:before { content: fa-content($fa-var-moon); }
.#{$fa-css-prefix}-mouse-pointer:before { content: fa-content($fa-var-mouse-pointer); }
.#{$fa-css-prefix}-newspaper:before { content: fa-content($fa-var-newspaper); }
.#{$fa-css-prefix}-paper-plane:before { content: fa-content($fa-var-paper-plane); }
.#{$fa-css-prefix}-pennant:before { content: fa-content($fa-var-pennant); }
.#{$fa-css-prefix}-play:before { content: fa-content($fa-var-play); }
.#{$fa-css-prefix}-plus:before { content: fa-content($fa-var-plus); }
.#{$fa-css-prefix}-question:before { content: fa-content($fa-var-question); }
.#{$fa-css-prefix}-quote-left:before { content: fa-content($fa-var-quote-left); }
.#{$fa-css-prefix}-quote-right:before { content: fa-content($fa-var-quote-right); }
.#{$fa-css-prefix}-search:before { content: fa-content($fa-var-search); }
.#{$fa-css-prefix}-search-minus:before { content: fa-content($fa-var-search-minus); }
.#{$fa-css-prefix}-search-plus:before { content: fa-content($fa-var-search-plus); }
.#{$fa-css-prefix}-sign-in:before { content: fa-content($fa-var-sign-in); }
.#{$fa-css-prefix}-sign-out:before { content: fa-content($fa-var-sign-out); }
.#{$fa-css-prefix}-slash:before { content: fa-content($fa-var-slash); }
.#{$fa-css-prefix}-sort:before { content: fa-content($fa-var-sort); }
.#{$fa-css-prefix}-sort-down:before { content: fa-content($fa-var-sort-down); }
.#{$fa-css-prefix}-sort-up:before { content: fa-content($fa-var-sort-up); }
.#{$fa-css-prefix}-star:before { content: fa-content($fa-var-star); }
.#{$fa-css-prefix}-stop:before { content: fa-content($fa-var-stop); }
.#{$fa-css-prefix}-times:before { content: fa-content($fa-var-times); }
.#{$fa-css-prefix}-trash-alt:before { content: fa-content($fa-var-trash-alt); }
.#{$fa-css-prefix}-twitter:before { content: fa-content($fa-var-twitter); }
.#{$fa-css-prefix}-undo-alt:before { content: fa-content($fa-var-undo-alt); }
.#{$fa-css-prefix}-user:before { content: fa-content($fa-var-user); }
.#{$fa-css-prefix}-vimeo:before { content: fa-content($fa-var-vimeo); }
.#{$fa-css-prefix}-window-restore:before { content: fa-content($fa-var-window-restore); }
.#{$fa-css-prefix}-youtube:before { content: fa-content($fa-var-youtube); }
