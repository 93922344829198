@import url('https://fonts.googleapis.com/css?family=Lato|Noto+Sans+JP:400,900&display=swap&subset=japanese');

/* Yu Gothic for Windows */
@font-face{
    font-family: MyYuGothic;
    font-weight: normal;
    src: local(YuGothic-Medium),
    local("Yu Gothic Medium");
    /* for Chrome */
}

@font-face{
    font-family: MyYuGothic;
    font-weight: bold;
    src: local(YuGothic-Bold),
    local("Yu Gothic");
    /* for Chrome */
}

body{
    font-family: sans-serif;
    font-feature-settings: "palt";
    visibility: hidden;
}

body[data-os^="Windows"]{
    font-family: "Lato","Noto Sans JP", sans-serif;
    font-feature-settings: "pkna";
    visibility: visible;

    .ie &{
        font-feature-settings: initial;
    }
}

body[data-os="Windows 10"]{
    font-family: "Lato","Noto Sans JP", sans-serif;
    font-feature-settings: "pkna";
    visibility: visible;

    .ie &{
        font-feature-settings: initial;
    }
}

body[data-os^="Mac OS"]{
    font-family: "Lato","Noto Sans JP", sans-serif;
    font-feature-settings: "palt";
    visibility: visible;

    .safari &{
        font-feature-settings: "pkna";
    }
}

body[data-os^="iOS"]{
    font-family: sans-serif;
    font-feature-settings: "palt";
    visibility: visible;
}

body[data-os^="Android"]{
    font-family: Roboto, sans-serif;
    font-feature-settings: "palt";
    visibility: visible;
}

body[data-os^="Android 4"] ,
body[data-os^="Android 5"]{
    font-family: Roboto, MotoyaLCedar, sans-serif;
    font-feature-settings: "palt";
    visibility: visible;
}

body[data-os^="undefined"]{
    font-family: sans-serif;
    font-feature-settings: "palt";
    visibility: visible;
}



// Noto Sans JP
.f-jp_m{
    font-family: "Noto Sans JP", sans-serif !important;
    font-weight: 400 !important;
}

%f-jp_m{
    font-family: "Noto Sans JP", sans-serif !important;
    font-weight: 400 !important;
}

@mixin f-jp_m{
    font-family: "Noto Sans JP", sans-serif !important;
    font-weight: 400 !important;
}

.f-jp_b{
    font-family: "Noto Sans JP", sans-serif !important;
    font-weight: 900 !important;
}

%f-jp_b{
    font-family: "Noto Sans JP", sans-serif !important;
    font-weight: 900 !important;
}

@mixin f-jp_b{
    font-family: "Noto Sans JP", sans-serif !important;
    font-weight: 900 !important;
}

// Noto Sans JP
.f-en_m{
    font-family: "Lato","Noto Sans JP", sans-serif !important;
    font-weight: 400 !important;
}

%f-en_m{
    font-family: "Lato","Noto Sans JP", sans-serif !important;
    font-weight: 400 !important;
}

@mixin f-en_m{
    font-family: "Lato","Noto Sans JP", sans-serif !important;
    font-weight: 400 !important;
}

.f-en_b{
    font-family: "Lato","Noto Sans JP", sans-serif !important;
    font-weight: 900 !important;
}

%f-en_b{
    font-family: "Lato","Noto Sans JP", sans-serif !important;
    font-weight: 900 !important;
}

@mixin f-en_b{
    font-family: "Lato","Noto Sans JP", sans-serif !important;
    font-weight: 900 !important;
}

/*
    mixin
*/
@mixin f-size_H1{
    @include font-size(90.44);
    /*
        tablet
        (740px to 960px)
    */
    @include mq($from: tablet, $until: desktop) {
        @include font-size(79.14);
    }
    /*
        mobile
        (320px to 480px)
    */
    @include mq($from: mobile, $until: tablet) {
        @include font-size(67.83);
    }
}
@mixin f-size_H2{
    @include font-size(63.96);
    /*
        tablet
        (740px to 960px)
    */
    @include mq($from: tablet, $until: desktop) {
        @include font-size(55.97);
    }
    /*
        mobile
        (320px to 480px)
    */
    @include mq($from: mobile, $until: tablet) {
        @include font-size(47.97);
    }
}
@mixin f-size_H3{
    @include font-size(45.23);
    /*
        tablet
        (740px to 960px)
    */
    @include mq($from: tablet, $until: desktop) {
        @include font-size(39.58);
    }
    /*
        mobile
        (320px to 480px)
    */
    @include mq($from: mobile, $until: tablet) {
        @include font-size(33.93);
    }
}
@mixin f-size_H4{
    @include font-size(31.99);
    /*
        tablet
        (740px to 960px)
    */
    @include mq($from: tablet, $until: desktop) {
        @include font-size(27.99);
    }
    /*
        mobile
        (320px to 480px)
    */
    @include mq($from: mobile, $until: tablet) {
        @include font-size(23.99);
    }
}
@mixin f-size_H5{
    @include font-size(22.62);
    /*
        tablet
        (740px to 960px)
    */
    @include mq($from: tablet, $until: desktop) {
        @include font-size(19.80);
    }
    /*
        mobile
        (320px to 480px)
    */
    @include mq($from: mobile, $until: tablet) {
        @include font-size(16.97);
    }
}
@mixin f-size_H6{
    @include font-size(16);
    line-height: 1.875;
    /*
        tablet
        (740px to 960px)
    */
    @include mq($from: tablet, $until: desktop) {
        @include font-size(14);
    }
    /*
        mobile
        (320px to 480px)
    */
    @include mq($from: mobile, $until: tablet) {
        @include font-size(11.3);
    }
}
@mixin f-size_XL{
    @include font-size(31.99);
    /*
        tablet
        (740px to 960px)
    */
    @include mq($from: tablet, $until: desktop) {
        @include font-size(27.99);
    }
    /*
        mobile
        (320px to 480px)
    */
    @include mq($from: mobile, $until: tablet) {
        @include font-size(23.99);
    }
}
@mixin f-size_L{
    @include font-size(22.62);
    /*
        tablet
        (740px to 960px)
    */
    @include mq($from: tablet, $until: desktop) {
        @include font-size(19.80);
    }
    /*
        mobile
        (320px to 480px)
    */
    @include mq($from: mobile, $until: tablet) {
        @include font-size(16.97);
    }
}
@mixin f-size_M{
    @include font-size(16);
    line-height: 1.875;
    /*
        tablet
        (740px to 960px)
    */
    @include mq($from: tablet, $until: desktop) {
        @include font-size(16);
    }
    /*
        mobile
        (320px to 480px)
    */
    @include mq($from: mobile, $until: tablet) {
        @include font-size(14);
    }
}
@mixin f-size_S{
    @include font-size(14);
    line-height: 1.714;
    /*
        tablet
        (740px to 960px)
    */
    @include mq($from: tablet, $until: desktop) {
        @include font-size(11.3);
    }
    /*
        mobile
        (320px to 480px)
    */
    @include mq($from: mobile, $until: tablet) {
        @include font-size(10);
    }
}
@mixin f-size_XS{
    @include font-size(11.3);
    line-height: 1.811;
    /*
        tablet
        (740px to 960px)
    */
    @include mq($from: tablet, $until: desktop) {
        @include font-size(10);
    }
    /*
        mobile
        (320px to 480px)
    */
    @include mq($from: mobile, $until: tablet) {
        @include font-size(10);
    }
}

.f-size_H1{
    @include f-size_H1;
}
.f-size_H2{
    @include f-size_H2;
}
.f-size_H3{
    @include f-size_H3;
}
.f-size_H4{
    @include f-size_H4;
}
.f-size_H5{
    @include f-size_H5;
}
.f-size_H6{
    @include f-size_H6;
}
.f-size_XL{
    @include f-size_XL;
}
.f-size_L{
    @include f-size_L;
}
.f-size_M{
    @include f-size_M;
}
.f-size_S{
    @include f-size_S;
}
.f-size_XS{
    @include f-size_XS;
}