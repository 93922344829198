$mq-responsive: true;
$mq-breakpoints: (
    mobile:  320px,
    mobileLandscape: 480px,
    tablet:  740px,
    desktop: 1000px,
    wide:    1280px
);
$mq-show-breakpoints: (
    // mobile,
    // mobileLandscape,
    // tablet,
    // desktop,
    // wide
);

/*
    mobile
    (320px to 480px)
*/
@include mq($from: mobile, $until: mobileLandscape){
}
/*
    mobileLandScape
    (480px to 740px)
*/
@include mq($from: mobileLandscape, $until: tablet){
}
/*
    tablet
    (740px to 960px)
*/
@include mq($from: tablet, $until: desktop){
}
/*
    tablet
    (960px to 1280px)
*/
@include mq($from: desktop, $until: wide){
}